/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import store from '@/store';
import { REMOVE_CURRENT_USER, SET_AUTH_STEP } from '@/store/action.types';
import { ONRAMP_FREE_TIER_KEYS } from '~reactHelpers';
// eslint-disable-next-line
import { strings } from '~utils/strings';
import { sendRequest } from '../utils/request';

const recoverEmailSent = () =>
  import(
    /* webpackChunkName: "confirmation" */ '@/views/auth/recover/recover-email-sent/index.vue'
  );
const confirmationSuccess = () =>
  import(
    /* webpackChunkName: "confirmation" */ '@/views/auth/register/confirmation-success/index.vue'
  );
const checkConfirmation = () =>
  import(
    /* webpackChunkName: "confirmation" */ '@/views/auth/register/check-confirmation/index.vue'
  );
const newOrganization = () =>
  import(/* webpackChunkName: "confirmation" */ '@/views/neworg/newOrganization.vue');

const setupOnRampLocalStorageData = (to) => {
  if (to.query.source) {
    localStorage.setItem(ONRAMP_FREE_TIER_KEYS.source, to.query.source);
  }
  if (to.query.callbackUrl) {
    localStorage.setItem(ONRAMP_FREE_TIER_KEYS.callbackUrl, to.query.callbackUrl);
  }
};

export default [
  {
    path: '/auth/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/login/index.vue'),
    meta: { title: strings.metaTitle + strings.Login },
  },
  {
    path: '/auth/sso',
    name: 'login-sso',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/sso/index.vue'),
    meta: { title: strings.metaTitle + strings.navigation.loginWithSSO },
  },
  {
    path: '/sign-terms-of-service',
    name: strings.routes.signTermsOfService,
    component: () =>
      import(/* webpackChunkName: "legal" */ '@/views/legal/sign-terms-of-service/index.vue'),
    meta: { title: strings.metaTitle + strings.termsOfServices },
  },
  {
    path: '/auth/register',
    alias: '/datadog-activation',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/register/index.vue'),
    children: [
      {
        path: '',
        name: 'register',
        props: true,
        component: () =>
          import(/* webpackChunkName: "auth" */ '@/views/auth/register/user-info/index.vue'),
        meta: { title: strings.metaTitle + strings.Register },
        beforeEnter(to, from, next) {
          setupOnRampLocalStorageData(to);
          const currentUser = store.getters.user;
          if (
            currentUser &&
            currentUser.FirstName &&
            currentUser.LastName &&
            currentUser.Confirmed
          ) {
            next({ name: 'orgs' });
          } else if (currentUser && currentUser.FirstName && currentUser.LastName) {
            next({ name: 'check-confirmation' });
          } else {
            next();
          }
        },
      },
      {
        path: 'profile-info',
        name: 'profile-info',
        props: true,
        component: () =>
          import(/* webpackChunkName: "auth" */ '@/views/auth/register/profile-info/index.vue'),
        meta: { title: strings.metaTitle + strings.profileCompletion },
        beforeEnter: (to, from, next) => {
          const currentUser = store.getters.user;
          if (currentUser && currentUser.FirstName && currentUser.LastName) {
            next({ name: 'orgs' });
          } else {
            store.commit(SET_AUTH_STEP, 2);
            next();
          }
        },
      },
      {
        path: 'check-confirmation',
        name: 'check-confirmation',
        component: checkConfirmation,
        meta: { title: strings.metaTitle + strings.confirmAccount },
        beforeEnter: async (to, from, next) => {
          await store.commit(SET_AUTH_STEP, 3);
          const { memberships, user } = store.getters;

          if (user && user.Confirmed) {
            if (memberships.length) {
              next({ name: 'orgs' });
            } else {
              next({ name: 'auth-new-organization' });
            }
          } else {
            next();
          }
        },
      },
      {
        path: 'confirmation-success',
        name: 'confirmation-success',
        component: confirmationSuccess,
        meta: { title: strings.metaTitle + strings.accountCreated },
        beforeEnter: async (to, from, next) => {
          await store.commit(SET_AUTH_STEP, 3);
          next();
        },
      },
      {
        path: 'new-organization',
        name: 'auth-new-organization',
        component: newOrganization,
        meta: { title: strings.metaTitle + strings.createNewOrg },
      },
    ],
  },
  {
    path: '/auth/logout',
    name: 'logout',
    meta: { title: strings.metaTitle + strings.Logout },
    beforeEnter: async (to, from, next) => {
      await store.commit(REMOVE_CURRENT_USER);
      await sendRequest('GET', '/v0/auth/logout', { blockAllOtherRequests: true });
      next({ name: 'login' });
    },
  },
  {
    path: '/auth/confirm',
    name: 'confirm',
    meta: { title: strings.metaTitle + strings.confirmAccount },
    beforeEnter: async (to, from, next) => {
      const data = {
        cnf: to.query.cnf,
      };
      const results = await sendRequest('POST', '/v0/auth/confirm', {
        data,
        showSuccessAlert: false,
      });
      if (results && results.Success !== '') {
        next({ name: 'confirmation-success' });
      } else {
        next({ name: 'check-confirmation' });
      }
    },
  },
  {
    path: '/auth/recover',
    name: 'recover-start',
    meta: { title: strings.metaTitle + strings.passwordRecovery },
    component: () =>
      import(/* webpackChunkName: "auth" */ '@/views/auth/recover/recover-start/index.vue'),
  },
  {
    path: '/auth/recover/end',
    name: 'recover-end',
    meta: { title: strings.metaTitle + strings.passwordRecovery },
    component: () =>
      import(/* webpackChunkName: "auth" */ '@/views/auth/recover/recover-end/index.vue'),
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    meta: { title: strings.metaTitle + strings.passwordRecovery },
    beforeEnter: async (to, from, next) => {
      let token = '';
      try {
        const results = await sendRequest('PUT', '/v0/password-reset', { showSuccessAlert: false });
        token = results.Token;
      } catch (e) {
        next({
          name: 'login',
          query: {
            forceLogin: true,
            redir: '/password-reset',
          },
        });
        return;
      }
      next({
        name: 'recover-end',
        query: { token },
      });
    },
  },
  {
    path: '/recover-email-sent',
    name: 'recover-email-sent',
    meta: { title: strings.metaTitle + strings.passwordRecovery },
    component: recoverEmailSent,
  },
];
