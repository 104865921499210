import { GroupBase, StylesConfig } from 'react-select';

import RepositoriesIcon from '~reactIcons/Navigation/Repositories.icon.react';
import HomeIcon from '~reactIcons/Navigation/Home.icon.react';
import ActionItemsIcon from '~reactIcons/Navigation/ActionItems.icon.react';
import PolicyIcon from '~reactIcons/Navigation/Policy.icon.react';
import AutomationIcon from '~reactIcons/Navigation/Automation.icon.react';
import VulnerabilitiesIcon from '~reactIcons/Navigation/Vulnerabilities.icon.react';
import ComplianceIcon from '~reactIcons/Navigation/Compliance.icon.react';
import InstallHubIcon from '~reactIcons/Navigation/InstallHub.icon.react';
import OrgIcon from '~reactIcons/Navigation/Organization.icon.react';
import HelpIcon from '~reactIcons/Navigation/Help.icon.react';
import ProfileIcon from '~reactIcons/Navigation/Profile.icon.react';
import SettingsIcon from '~reactIcons/Navigation/Settings.icon.react';
import CollapseIcon from '~reactIcons/Navigation/Collapse.icon.react';
import ClustersIcon from '~reactIcons/Navigation/Clusters.icon.react';
import EfficiencyIcon from '~reactIcons/Navigation/Efficiency.icon.react';
import AppGroupsIcon from '~reactIcons/Navigation/AppGroups.icon.react';

import { OptionType } from '~globalTypes';

import { strings } from '~utils/strings';
import { vexillaShould } from '~utils/feature-flags';
import { COLORS } from '~utils/styling';
import AdmissionControllerIcon from '~reactComponents/Icons/Navigation/AdmissionController.icon.react';

export interface NavigationItem {
  label: string;
  title?: string;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  section: string[];
  expandableItems?: ExpandableItem[];
  route?: Route;
  secondaryNav?: SecondaryNavigation[];
  showClusterDropdown?: boolean;
  showRepositoriesDropdown?: boolean;
  showCostsSettings?: boolean;
  isHidden?: boolean;
  sameRoutes?: string[];
}

export type ExpandableItem = Pick<
  NavigationItem,
  | 'label'
  | 'route'
  | 'secondaryNav'
  | 'showClusterDropdown'
  | 'showRepositoriesDropdown'
  | 'title'
  | 'showCostsSettings'
>;

export type RouteType = 'route' | 'link' | 'action';

export interface Route {
  name?: string;
  params?: Record<string, string>;
  dependencies?: string[];
  type: RouteType;
}

export type SecondaryNavigation = Pick<NavigationItem, 'label' | 'route' | 'icon' | 'isHidden'>;

export const REACT_REPOSITORIES = 'repositories';
export const REACT_REPOSITORY = 'repository';

// TODO: the below consts should be removed when the new vulnerabilities page has been verified and ready for launch.
export const REACT_VULNERABILITIES = 'react-vulnerabilities';
export const REACT_VULNERABILITIES_ALL_IMAGES_CLUSTER = 'react-vulnerabilities-all-images-cluster';
export const REACT_ADMISSION_REQUESTS_CLUSTER = 'admission-requests';
export const REACT_VULNERABILITIES_ALL_IMAGES = 'react-vulnerabilities-all-images';
export const REACT_VULNERABILITIES_ALL_VULNERABILITIES = 'react-vulnerabilities-all-vulnerabilities';
export const REACT_VULNERABILITIES_ALL_VULNERABILITIES_CLUSTER = 'react-vulnerabilities-all-vulnerabilities-cluster';
export const IMAGE_DETAIL = 'image-detail';
export const REACT_VULNERABILITIES_IMAGE_DETAIL = 'react-vulnerabilities-image-detail';
export const REACT_VULNERABILITIES_IMAGE_DETAIL_CLUSTER = 'react-vulnerabilities-image-detail-cluster';

export const EFFICIENCY = strings.noTranslate.efficiency;
export const COSTS = 'costs';
export const CUMULATIVE_COSTS = 'cumulative-costs';
export const RIGHT_SIZING = 'right-sizing';
export const CUMULATIVE_COSTS_CLUSTER = 'cumulative-costs-cluster';
export const RIGHT_SIZING_CLUSTER = 'right-sizing-cluster';
export const COSTS_SETTINGS = 'costs-settings';
export const COSTS_SETTINGS_CLUSTER = 'costs-settings-cluster';
export const ACTION_ITEMS = 'action-items';
export const ADMISSION_REQUESTS = 'admission-requests';
export const ADMISSION_REQUESTS_CLUSTER = 'admission-requests-cluster';
export const POLICY_WIZARD = 'policy-wizard';
export const REPORT_HUB = 'hub-report';
export const CLUSTER_REPORT_HUB = 'clusterreport-hub';
export const ACTION_ITEMS_CLUSTER = 'action-items-cluster';
export const ACTION_ITEMS_LISTS = 'action-items-lists';
export const ACTION_ITEMS_CLUSTER_LISTS = 'action-items-cluster-lists';
export const ORG_EFFICIENCY = 'org-efficiency';
export const NODE_EFFICIENCY = 'node-efficiency';
export const ORG_DASHBOARD = 'org-dashboard';
export const ORG_POLICY = 'org-policy';
export const ALL_POLICIES = 'all-policies';
export const CLUSTERS = 'clusters';
export const CLUSTER_OVERVIEW = 'clusteroverview';
export const INTEGRATIONS = 'integrations';
export const DATADOG = 'datadog';
export const EDITRULE = 'edit-rule';
export const EDITRULE_TEMPLATE = 'edit-rule-template';
export const NEWRULE = 'new-rule';
export const AUTOMATION = 'automation';
export const AUTOMATION_TEMPLATES = 'automation-templates';
export const ORGS = 'orgs';
export const COMPLIANCE = 'compliance';
export const COMPLIANCE_REPORT = 'report';
export const ACTION_ITEMS_REPORTS_PRINT_PREVIEW = 'action-items-reports-print-preview';
export const ACTION_ITEMS_REPORTS = 'action-items-reports';
export const ACTION_ITEMS_CLUSTER_REPORTS = 'action-items-cluster-reports';
export const ACTION_ITEMS_SUMMARY = 'action-items-summary';
export const ACTION_ITEMS_CLUSTER_SUMMARY = 'action-items-cluster-summary';
export const ACTION_ITEMS_CLUSTER_REPORTS_PRINT_PREVIEW = 'action-items-cluster-reports-print-preview';
export const LOGIN = 'login';
export const NEW_TEAM = 'new-team';
export const REPORT_HUB_ABOUT = 'hub-report-about';
export const REPORT_HUB_CONFIGURE = 'hub-report-configure';
export const REPORT_HUB_HISTORY = 'hub-report-history';
export const CLUSTER_HUB_INSTALL = 'clusterhub-install';
export const LOGIN_SSO = 'login-sso';
export const RECOVER_PASSWORD = 'recover-start';
export const RECOVER_EMAIL = 'recover-email-sent';
export const RECOVER_END = 'recover-end';
export const REGISTER = 'register';
export const CHECK_CONFIRMATION = 'check-confirmation';
export const PROFILE = 'profile';
export const EMAIL_NOTIFICATIONS = 'email-notifications';
export const REGION = 'region';
export const PASSWORD = 'password';
export const PASSWORD_RESET = 'reset-password';
export const TEAM_MANAGEMENT = 'team-management';
export const NOTIFICATIONS = 'notifications';
export const SSO = 'sso';
export const DANGER_ZONE = 'danger-zone';
export const TOKENS = 'tokens';
export const NEW_ORG = 'new-organization';
export const NEW_CLUSTER = 'new-cluster';
export const ADMIN = 'admin';
export const PROFILE_INFO = 'profile-info';
export const AUTH_NEW_ORG = 'auth-new-organization';
export const APP_GROUPS = 'app-groups';
export const APP_GROUPS_SUMMARY = 'app-groups-summary';
export const POLICY_MAPPING = 'policy-mapping';
export const CLUSTER_ADMISSION_CONTROLLER = 'clusteradmission-controller';

export const DEFAULT_CLUSTER_OPTIONS = [
  {
    value: '',
    label: strings.navigation.allClusters,
  },
];

export const DEFAULT_REPOSITORY_OPTIONS = [
  {
    value: '',
    label: strings.navigation.allRepos,
  },
];

export const SELECT_STYLES: StylesConfig<OptionType, true | false, GroupBase<OptionType>> = {
  menu: (provided) => ({
    ...provided,
    width: 'fit-content',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (base) => ({
    ...base,
    borderWidth: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    minWidth: '140px',
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: 0,
  }),
  multiValueRemove: (base) => ({ ...base, display: 'none' }),
  singleValue: (base) => ({
    ...base,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '21px',
    textAlign: 'left',
  }),
  option: (provided, state) => ({
    ...provided,
    color: COLORS.CORE.DROPDOWN_FONT,
  }),
};

const enabledCostsRightSizingTab = vexillaShould(strings.featureFlags.costsRightSizingTab);

/**
 * Config for the top navigation.
 * NOTE: the dependencies are read, and items will only appear if their dependencies are valid
 * This means an item will only be displayed if *all* of the dependencies are valid.
 */
export const SECONDARY_NAVIGATION_ITEMS: Record<string, SecondaryNavigation[]> = {
  clusteroverview: [
    {
      label: strings.navigation.Overview,
      route: {
        name: CLUSTER_OVERVIEW,
        dependencies: ['organization', 'cluster'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.addOns,
      route: {
        name: 'clusteradd-ons',
        dependencies: ['organization', 'cluster'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.rbac,
      route: {
        name: 'clusterrbac',
        dependencies: ['organization', 'cluster'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.admissionController,
      route: {
        name: CLUSTER_ADMISSION_CONTROLLER,
        dependencies: ['organization', 'cluster'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.installHub,
      route: {
        name: CLUSTER_REPORT_HUB,
        type: 'route',
      },
      icon: InstallHubIcon,
    },
    {
      label: '',
      route: {
        name: CLUSTER_HUB_INSTALL,
        type: 'route',
      },
      isHidden: true,
    },
    {
      label: '',
      route: {
        name: REPORT_HUB_CONFIGURE,
        type: 'route',
      },
      isHidden: true,
    },
  ],
  actionItems: [
    {
      label: strings.navigation.summary,
      route: {
        name: ACTION_ITEMS_SUMMARY,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.Lists,
      route: {
        name: ACTION_ITEMS_LISTS,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.allItems,
      route: {
        name: ACTION_ITEMS,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.reports,
      route: {
        name: ACTION_ITEMS_REPORTS,
        dependencies: ['organization'],
        type: 'route',
      },
    },
  ],
  actionItemsCluster: [
    {
      label: strings.navigation.summary,
      route: {
        name: ACTION_ITEMS_CLUSTER_SUMMARY,
        dependencies: ['organization', 'cluster'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.Lists,
      route: {
        name: ACTION_ITEMS_CLUSTER_LISTS,
        dependencies: ['organization', 'cluster'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.allItems,
      route: {
        name: ACTION_ITEMS_CLUSTER,
        dependencies: ['organization', 'cluster'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.reports,
      route: {
        name: ACTION_ITEMS_CLUSTER_REPORTS,
        dependencies: ['organization', 'cluster'],
        type: 'route',
      },
    },
  ],
  profile: [
    {
      label: strings.navigation.userInformation,
      route: {
        name: PROFILE,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.notifications,
      route: {
        name: EMAIL_NOTIFICATIONS,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.Region,
      route: {
        name: REGION,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.Password,
      route: {
        name: PASSWORD,
        dependencies: ['organization'],
        type: 'route',
      },
    },
  ],
  settings: [
    {
      label: strings.navigation.teamManagement,
      route: {
        name: TEAM_MANAGEMENT,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.notifications,
      route: {
        name: NOTIFICATIONS,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.Integrations,
      route: {
        name: INTEGRATIONS,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.tokens,
      route: {
        name: TOKENS,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.SSO,
      route: {
        name: SSO,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.dangerZone,
      route: {
        name: DANGER_ZONE,
        dependencies: ['organization'],
        type: 'route',
      },
    },
  ],
  policy: [
    {
      label: strings.navigation.allPolicies,
      route: {
        name: ALL_POLICIES,
        dependencies: ['organization', 'cluster'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.policyMapping,
      route: {
        name: POLICY_MAPPING,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.opaTemplates,
      route: {
        name: 'policy-templates',
        dependencies: ['organization', 'cluster'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.opaWizard,
      route: {
        name: POLICY_WIZARD,
        dependencies: ['organization', 'cluster'],
        type: 'route',
      },
    },
  ],
  efficiency: enabledCostsRightSizingTab
    ? [
        {
          label: strings.navigation.Capacity,
          route: {
            name: ORG_EFFICIENCY,
            dependencies: ['organization'],
            type: 'route',
          },
        },
        {
          label: 'Costs',
          route: {
            name: CUMULATIVE_COSTS,
            dependencies: ['organization'],
            type: 'route',
          },
        },
        {
          label: 'Right-Sizing',
          route: {
            name: RIGHT_SIZING,
            dependencies: ['organization'],
            type: 'route',
          },
        },
        {
          label: strings.topNav.Settings,
          route: {
            name: COSTS_SETTINGS,
            dependencies: ['organization'],
            type: 'route',
          },
        },
      ]
    : [
        {
          label: strings.navigation.Capacity,
          route: {
            name: ORG_EFFICIENCY,
            dependencies: ['organization'],
            type: 'route',
          },
        },
        {
          label: 'Costs',
          route: {
            name: CUMULATIVE_COSTS,
            dependencies: ['organization'],
            type: 'route',
          },
        },
        {
          label: strings.topNav.Settings,
          route: {
            name: COSTS_SETTINGS,
            dependencies: ['organization'],
            type: 'route',
          },
        },
      ],
  efficiencyCluster: enabledCostsRightSizingTab
    ? [
        {
          label: strings.navigation.Capacity,
          route: {
            name: NODE_EFFICIENCY,
            dependencies: ['organization', 'cluster'],
            type: 'route',
          },
        },
        {
          label: 'Costs',
          route: {
            name: CUMULATIVE_COSTS_CLUSTER,
            dependencies: ['organization', 'cluster'],
            type: 'route',
          },
        },
        {
          label: 'Right-Sizing',
          route: {
            name: RIGHT_SIZING_CLUSTER,
            dependencies: ['organization', 'cluster'],
            type: 'route',
          },
        },
        {
          label: strings.topNav.Settings,
          route: {
            name: COSTS_SETTINGS_CLUSTER,
            dependencies: ['organization', 'cluster'],
            type: 'route',
          },
        },
      ]
    : [
        {
          label: strings.navigation.Capacity,
          route: {
            name: NODE_EFFICIENCY,
            dependencies: ['organization', 'cluster'],
            type: 'route',
          },
        },
        {
          label: 'Costs',
          route: {
            name: CUMULATIVE_COSTS_CLUSTER,
            dependencies: ['organization', 'cluster'],
            type: 'route',
          },
        },
        {
          label: strings.topNav.Settings,
          route: {
            name: COSTS_SETTINGS_CLUSTER,
            dependencies: ['organization', 'cluster'],
            type: 'route',
          },
        },
      ],
  admissionRequests: [
    {
      label: strings.navigation.admissionRequests,
      route: {
        name: ADMISSION_REQUESTS,
        dependencies: ['organization'],
        type: 'route',
      },
    },
  ],
  admissionRequestsCluster: [
    {
      label: strings.navigation.admissionRequests,
      route: {
        name: ADMISSION_REQUESTS_CLUSTER,
        dependencies: ['organization', 'cluster'],
        type: 'route',
      },
    },
  ],
  automation: [
    {
      label: strings.navigation.allRules,
      route: {
        name: AUTOMATION,
        dependencies: ['org'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.ruleWizard,
      route: {
        name: NEWRULE,
        dependencies: ['org'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.ruleWizard,
      route: {
        name: EDITRULE,
        dependencies: ['org'],
        type: 'route',
      },
      isHidden: true,
    },
    {
      label: strings.navigation.ruleWizard,
      route: {
        name: AUTOMATION_TEMPLATES,
        dependencies: ['org'],
        type: 'route',
      },
      isHidden: true,
    },
    {
      label: strings.navigation.ruleWizard,
      route: {
        name: EDITRULE_TEMPLATE,
        dependencies: ['org'],
        type: 'route',
      },
      isHidden: true,
    },
    {
      label: strings.navigation.logs,
      route: {
        name: 'automation-logs',
        dependencies: ['org'],
        type: 'route',
      },
    },
  ],
  repositoriesOverview: [
    {
      label: strings.navigation.Overview,
      route: {
        name: REACT_REPOSITORIES,
        dependencies: ['organization'],
        type: 'route',
      },
    },
  ],
  repositoryOverview: [
    {
      label: strings.navigation.Overview,
      route: {
        name: REACT_REPOSITORY,
        dependencies: ['organization'],
        type: 'route',
      },
    },
  ],
  vulnerabilities: [
    {
      label: strings.navigation.allImages,
      route: {
        name: REACT_VULNERABILITIES_ALL_IMAGES,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.allVulns,
      route: {
        name: 'react-vulnerabilities-all-vulnerabilities',
        dependencies: ['organization'],
        type: 'route',
      },
    },
  ],
  vulnerabilitiesImageDetail: [
    {
      label: strings.navigation.allImages,
      route: {
        name: 'react-vulnerabilities-image-detail',
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.allVulns,
      route: {
        name: 'react-vulnerabilities-all-vulnerabilities',
        dependencies: ['organization'],
        type: 'route',
      },
    },
  ],
  vulnerabilitiesCluster: [
    {
      label: strings.navigation.allImages,
      route: {
        name: REACT_VULNERABILITIES_ALL_IMAGES_CLUSTER,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.allVulns,
      route: {
        name: 'react-vulnerabilities-all-vulnerabilities-cluster',
        dependencies: ['organization'],
        type: 'route',
      },
    },
  ],
  vulnerabilitiesImageDetailCluster: [
    {
      label: strings.navigation.allImages,
      route: {
        name: REACT_VULNERABILITIES_IMAGE_DETAIL_CLUSTER,
        dependencies: ['organization'],
        type: 'route',
      },
    },
    {
      label: strings.navigation.allVulns,
      route: {
        name: 'react-vulnerabilities-all-vulnerabilities-cluster',
        dependencies: ['organization'],
        type: 'route',
      },
    },
  ],
  appGroups: [
    {
      label: strings.appGroups.summary,
      route: {
        name: APP_GROUPS_SUMMARY,
        dependencies: ['organization', 'cluster'],
        type: 'route',
      },
    },
  ],
};

export const MAIN_NAVIGATION_ITEMS = (route: string): Record<string, NavigationItem[]> => ({
  top: [
    {
      label: strings.Home,
      title: 'Home',
      icon: HomeIcon,
      section: [CLUSTERS, 'organization', 'all'],
      route: {
        name: ORG_DASHBOARD,
        dependencies: ['org'],
        type: 'route',
      },
      showClusterDropdown: false,
    },
    {
      label: strings.appGroups.AppGroups,
      title: 'All App Groups',
      icon: AppGroupsIcon,
      section: [CLUSTERS, 'organization', 'all'],
      route: {
        name: APP_GROUPS_SUMMARY,
        dependencies: ['org'],
        type: 'route',
      },
      secondaryNav: SECONDARY_NAVIGATION_ITEMS.appGroups,
      showClusterDropdown: false,
    },
    {
      label: strings.navigation.Clusters,
      title: 'Clusters',
      icon: ClustersIcon,
      section: ['organization', 'all'],
      route: {
        name: CLUSTERS,
        dependencies: ['org'],
        type: 'route',
      },
      showClusterDropdown: true,
    },
    {
      label: strings.navigation.Clusters,
      title: 'Clusters',
      icon: ClustersIcon,
      section: [CLUSTERS],
      route: {
        name: CLUSTERS,
        dependencies: ['org'],
        type: 'route',
      },
      secondaryNav: SECONDARY_NAVIGATION_ITEMS.clusteroverview,
      showClusterDropdown: true,
    },
    {
      label: strings.navigation.ActionItems,
      title: 'Action Items',
      icon: ActionItemsIcon,
      section: ['organization', 'all'],
      route: {
        name: ACTION_ITEMS,
        dependencies: ['org'],
        type: 'route',
      },
      secondaryNav: SECONDARY_NAVIGATION_ITEMS.actionItems,
      showClusterDropdown: true,
    },
    {
      label: strings.navigation.ActionItems,
      title: 'Action Items',
      icon: ActionItemsIcon,
      section: [CLUSTERS],
      route: {
        name: ACTION_ITEMS,
        dependencies: ['org'],
        type: 'route',
      },
      secondaryNav: SECONDARY_NAVIGATION_ITEMS.actionItemsCluster,
      showClusterDropdown: true,
    },
    {
      label: strings.navigation.Efficiency,
      title: strings.noTranslate.Efficiency,
      icon: EfficiencyIcon,
      section: ['organization', 'all'],
      route: {
        name: ORG_EFFICIENCY,
        dependencies: ['org'],
        type: 'route',
      },
      secondaryNav: SECONDARY_NAVIGATION_ITEMS.efficiency,
      showClusterDropdown: true,
    },
    {
      label: strings.navigation.Efficiency,
      title: strings.noTranslate.Efficiency,
      icon: EfficiencyIcon,
      section: [CLUSTERS],
      route: {
        name: NODE_EFFICIENCY,
        dependencies: ['org'],
        type: 'route',
      },
      secondaryNav: SECONDARY_NAVIGATION_ITEMS.efficiencyCluster,
      showClusterDropdown: true,
    },
    {
      label: strings.navigation.Vulnerabilities,
      title: 'Vulnerabilities',
      icon: VulnerabilitiesIcon,
      section: ['organization', CLUSTERS, 'all'],
      route: {
        name: REACT_VULNERABILITIES_ALL_IMAGES,
        dependencies: ['org'],
        type: 'route',
      },
      secondaryNav: SECONDARY_NAVIGATION_ITEMS.vulnerabilities,
      showClusterDropdown: true,
    },
    {
      label: strings.navigation.vulnsImageDetail,
      title: 'Vulnerabilities Image Detail',
      icon: VulnerabilitiesIcon,
      section: ['organization', CLUSTERS, 'all'],
      route: {
        name: 'react-vulnerabilities-image-detail',
        dependencies: ['org'],
        type: 'route',
      },
      secondaryNav: SECONDARY_NAVIGATION_ITEMS.vulnerabilitiesImageDetail,
      showClusterDropdown: true,
    },
    {
      label: strings.navigation.vulnsCluster,
      title: 'Vulnerabilities Cluster',
      icon: VulnerabilitiesIcon,
      section: ['organization', CLUSTERS, 'all'],
      route: {
        name: REACT_VULNERABILITIES_ALL_IMAGES_CLUSTER,
        dependencies: ['org'],
        type: 'route',
      },
      secondaryNav: SECONDARY_NAVIGATION_ITEMS.vulnerabilitiesCluster,
      showClusterDropdown: true,
    },
    {
      label: strings.navigation.vulnsImageDetailCluster,
      title: 'Vulnerabilities Image Detail Cluster',
      icon: VulnerabilitiesIcon,
      section: ['organization', 'all'],
      route: {
        name: REACT_VULNERABILITIES_IMAGE_DETAIL_CLUSTER,
        dependencies: ['org'],
        type: 'route',
      },
      secondaryNav: SECONDARY_NAVIGATION_ITEMS.vulnerabilitiesImageDetailCluster,
      showClusterDropdown: true,
    },
    {
      label: strings.navigation.Repositories,
      title: 'Repositories',
      icon: RepositoriesIcon,
      section: ['organization', CLUSTERS, 'all'],
      route: {
        name: REACT_REPOSITORIES,
        dependencies: ['org'],
        type: 'route',
      },
      secondaryNav:
        route === REACT_REPOSITORIES
          ? SECONDARY_NAVIGATION_ITEMS.repositoriesOverview
          : SECONDARY_NAVIGATION_ITEMS.repositoryOverview,
      showClusterDropdown: false,
      showRepositoriesDropdown: true,
    },
    {
      label: strings.navigation.AdmissionRequests,
      title: strings.noTranslate.AdmissionRequests,
      icon: AdmissionControllerIcon,
      section: ['organization', CLUSTERS, 'all'],
      route: {
        name: ADMISSION_REQUESTS,
        dependencies: ['org'],
        type: 'route',
      },
      showClusterDropdown: true,
    },
    {
      label: strings.navigation.Compliance,
      title: 'Compliance',
      icon: ComplianceIcon,
      section: ['organization', CLUSTERS, 'all'],
      route: {
        name: COMPLIANCE,
        dependencies: ['org'],
        type: 'route',
      },
      showClusterDropdown: false,
      sameRoutes: [COMPLIANCE_REPORT],
    },
    {
      label: strings.navigation.Policy,
      title: 'Policy',
      icon: PolicyIcon,
      section: ['organization', CLUSTERS, 'all'],
      route: {
        name: ALL_POLICIES,
        dependencies: ['org'],
        type: 'route',
      },
      showClusterDropdown: false,
      secondaryNav: SECONDARY_NAVIGATION_ITEMS.policy,
    },
    {
      label: strings.navigation.Automation,
      title: strings.navigation.Automation,
      icon: AutomationIcon,
      section: ['organization', CLUSTERS, 'all'],
      route: {
        name: AUTOMATION,
        dependencies: ['org'],
        type: 'route',
      },
      secondaryNav: SECONDARY_NAVIGATION_ITEMS.automation,
      showClusterDropdown: false,
    },
  ],
  bottom: [
    {
      label: strings.navigation.Profile,
      title: strings.noTranslate.Profile,
      icon: ProfileIcon,
      section: ['all', CLUSTERS, 'organization'],
      expandableItems: [
        {
          label: strings.navigation.Admin,
          route: {
            name: ADMIN,
            dependencies: ['user', 'isSuperAdmin', 'org'],
            type: 'route',
          },
        },
        {
          label: strings.navigation.userSettings,
          route: {
            name: PROFILE,
            dependencies: ['org', 'user'],
            type: 'route',
          },
          secondaryNav: SECONDARY_NAVIGATION_ITEMS.profile,
          showClusterDropdown: false,
        },
        {
          label: strings.navigation.logOut,
          route: {
            name: 'logout',
            dependencies: ['user'],
            type: 'route',
          },
        },
      ],
      showClusterDropdown: false,
    },
    {
      label: strings.navigation.Organizations,
      title: 'Org Dashboard',
      icon: OrgIcon,
      section: ['all', CLUSTERS, 'organization'],
      expandableItems: [],
      showClusterDropdown: false,
    },
    {
      label: strings.navigation.Settings,
      title: strings.topNav.Settings,
      icon: SettingsIcon,
      section: [CLUSTERS, 'organization'],
      route: {
        name: 'default-settings',
        dependencies: ['org'],
        type: 'route',
      },
      secondaryNav: SECONDARY_NAVIGATION_ITEMS.settings,
      showClusterDropdown: false,
    },
    {
      label: strings.navigation.Help,
      title: 'Help',
      icon: HelpIcon,
      section: ['all', CLUSTERS, 'organization'],
      expandableItems: [
        {
          label: strings.navigation.Demo,
          route: {
            name: 'https://www.fairwinds.com/blog/configuration-validation-software-video-demo',
            dependencies: ['loggedOut'],
            type: strings.noTranslate.link,
          },
        },
        {
          label: strings.navigation.Documentation,
          route: {
            name: 'https://insights.docs.fairwinds.com/',
            type: strings.noTranslate.link,
          },
        },
        {
          label: strings.navigation.FAQ,
          route: {
            name: 'https://www.fairwinds.com/fairwinds-insights-faq',
            type: strings.noTranslate.link,
          },
        },
        {
          label: strings.navigation.statusPage,
          route: {
            name: 'https://fairwindsops.statuspage.io',
            type: strings.noTranslate.link,
          },
        },
      ],
    },
    {
      label: strings.navigation.Collapse,
      title: 'Collapse',
      icon: CollapseIcon,
      section: ['all', CLUSTERS, 'organization'],
      route: {
        type: 'action',
      },
    },
  ],
});

export const CENTAURUS_ROUTE: SecondaryNavigation = {
  label: strings.navigation.Centaurus,
  route: {
    name: ORG_DASHBOARD,
    params: {
      org: 'Centaurus',
    },
    type: 'route',
  },
};

export const RESTRICTED_ALL_CLUSTERS_OPTION_ROUTES: string[] = [
  'clusteradd-ons',
  'clusterrbac',
  'clusteradmission-controller',
  CLUSTER_REPORT_HUB,
  REPORT_HUB_CONFIGURE,
];

export const HIGHLIGHTED_ROUTES_CRITERIA = [
  {
    mainRoute: CLUSTERS,
    relatedRoutes: [NEW_CLUSTER],
  },
  {
    mainRoute: AUTOMATION,
    relatedRoutes: [AUTOMATION_TEMPLATES, NEWRULE, EDITRULE],
  },
  {
    mainRoute: ALL_POLICIES,
    relatedRoutes: [POLICY_WIZARD],
  },
  {
    mainRoute: COMPLIANCE,
    relatedRoutes: ['report'],
  },
  {
    mainRoute: REACT_VULNERABILITIES_ALL_IMAGES,
    relatedRoutes: [
      REACT_VULNERABILITIES_ALL_IMAGES_CLUSTER,
      REACT_VULNERABILITIES_ALL_VULNERABILITIES_CLUSTER,
      REACT_VULNERABILITIES_IMAGE_DETAIL,
    ],
  },
  {
    mainRoute: ADMISSION_REQUESTS,
    relatedRoutes: [ADMISSION_REQUESTS_CLUSTER],
  },
];

// define hidden routes here.
export const HIDDEN_ROUTES = [];

export const HIDE_LEFT_NAV_ROUTES = [
  REACT_VULNERABILITIES_ALL_IMAGES_CLUSTER,
  REACT_VULNERABILITIES_IMAGE_DETAIL,
  REACT_VULNERABILITIES_IMAGE_DETAIL_CLUSTER,
];
