import * as React from 'react';
import { SVGProps } from 'react';
import { COLORS } from '~utils/styling';

const AppGroupsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_5_696" fill={COLORS.CORE.WHITE}>
      <rect y="7" width="6" height="6" rx="0.5" />
    </mask>
    <rect
      y="7"
      width="6"
      height="6"
      rx="0.5"
      stroke={COLORS.CORE.WHITE}
      stroke-width="3"
      mask="url(#path-1-inside-1_5_696)"
    />
    <mask id="path-2-inside-2_5_696" fill={COLORS.CORE.WHITE}>
      <rect y="14" width="6" height="6" rx="0.5" />
    </mask>
    <rect
      y="14"
      width="6"
      height="6"
      rx="0.5"
      stroke={COLORS.CORE.WHITE}
      stroke-width="3"
      mask="url(#path-2-inside-2_5_696)"
    />
    <mask id="path-3-inside-3_5_696" fill={COLORS.CORE.WHITE}>
      <rect width="6" height="6" rx="0.5" />
    </mask>
    <rect
      width="6"
      height="6"
      rx="0.5"
      stroke={COLORS.CORE.WHITE}
      stroke-width="3"
      mask="url(#path-3-inside-3_5_696)"
    />
    <mask id="path-4-inside-4_5_696" fill={COLORS.CORE.WHITE}>
      <rect x="7" y="7" width="6" height="6" rx="0.5" />
    </mask>
    <rect
      x="7"
      y="7"
      width="6"
      height="6"
      rx="0.5"
      stroke={COLORS.CORE.WHITE}
      stroke-width="3"
      mask="url(#path-4-inside-4_5_696)"
    />
    <mask id="path-5-inside-5_5_696" fill={COLORS.CORE.WHITE}>
      <rect x="7" y="14" width="6" height="6" rx="0.5" />
    </mask>
    <rect
      x="7"
      y="14"
      width="6"
      height="6"
      rx="0.5"
      stroke={COLORS.CORE.WHITE}
      stroke-width="3"
      mask="url(#path-5-inside-5_5_696)"
    />
    <mask id="path-6-inside-6_5_696" fill={COLORS.CORE.WHITE}>
      <rect x="7" width="6" height="6" rx="0.5" />
    </mask>
    <rect
      x="7"
      width="6"
      height="6"
      rx="0.5"
      stroke={COLORS.CORE.WHITE}
      stroke-width="3"
      mask="url(#path-6-inside-6_5_696)"
    />
    <mask id="path-7-inside-7_5_696" fill={COLORS.CORE.WHITE}>
      <rect x="14" y="7" width="6" height="6" rx="0.5" />
    </mask>
    <rect
      x="14"
      y="7"
      width="6"
      height="6"
      rx="0.5"
      stroke={COLORS.CORE.WHITE}
      stroke-width="3"
      mask="url(#path-7-inside-7_5_696)"
    />
    <mask id="path-8-inside-8_5_696" fill={COLORS.CORE.WHITE}>
      <rect x="14" y="14" width="6" height="6" rx="0.5" />
    </mask>
    <rect
      x="14"
      y="14"
      width="6"
      height="6"
      rx="0.5"
      stroke={COLORS.CORE.WHITE}
      stroke-width="3"
      mask="url(#path-8-inside-8_5_696)"
    />
    <mask id="path-9-inside-9_5_696" fill={COLORS.CORE.WHITE}>
      <rect x="14" width="6" height="6" rx="0.5" />
    </mask>
    <rect
      x="14"
      width="6"
      height="6"
      rx="0.5"
      stroke={COLORS.CORE.WHITE}
      stroke-width="3"
      mask="url(#path-9-inside-9_5_696)"
    />
  </svg>
);

export default AppGroupsIcon;
